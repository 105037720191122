<template>
  <div class="work">
    <van-search
      v-model="searchValue"
      show-action
      placeholder="请输入搜索关键词"
      clearable
      @search="handlerSearch"
    >
      <template #action>
        <div @click="handlerSearch" style="color: #244e8a">搜索</div>
      </template>
    </van-search>
    <div class="container">
      <van-tabs
        v-model="activeName"
        :line-width="14"
        :line-height="4"
        color="#0069FF"
        title-active-color="#363A44"
        title-inactive-color="#686B73"
        background="#f6f7f8"
        @click="handleTabs"
      >
        <van-tab title="行政备案" name="行政备案">
          <ul class="wrapper">
            <li v-for="item in recordList" :key="item.id">
              <a :href="item.mobileUrl">
                <img :src="icons[item.name]" />
                <span class="van-multi-ellipsis--l2">{{ item.name }}</span>
              </a>
            </li>
          </ul>
        </van-tab>
        <van-tab title="行政许可" name="行政许可">
          <ul class="wrapper">
            <li v-for="item in recordList" :key="item.id">
              <a :href="item.mobileUrl">
                <img :src="icons[item.name]" />
                <span class="van-multi-ellipsis--l2">{{ item.name }}</span>
              </a>
            </li>
          </ul>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: '',
      activeName: '行政备案',
      icons: {
        '电影剧本（梗概）备案': require('@/assets/work/1.png'),
        '电影剧本（梗概）变更备案': require('@/assets/work/2.png'),
        '电影参加境外电影节（展）备案': require('@/assets/work/3.png'),
        国产电影属地审查: require('@/assets/work/4.png'),
        '电影放映经营许可证核发（外资）': require('@/assets/work/5.png'),
        承接境外电影的加工制作等业务备案: require('@/assets/work/6.png'),
        承接境外电影的后期制作等业务备案: require('@/assets/work/7.png'),
        承接境外电影的洗印制作等业务备案: require('@/assets/work/8.png'),
        企业从事电影流动放映活动备案: require('@/assets/work/9.png'),
        个人从事电影流动放映活动备案: require('@/assets/work/10.png'),
        电影院线公司的年检: require('@/assets/work/11.png'),
        '《电影放映经营许可证》年检': require('@/assets/work/12.png'),
        '电影放映经营许可证核发（内资）': require('@/assets/work/13.png'),
        '电影放映单位变更名称、地址、法定代表人（主要负责人）或终止电影放映经营活动备案': require('@/assets/work/14.png'),
        '省内电影院线公司的设立审批（含少儿电影发行放映院线、农村跨市经营数字电影院线）': require('@/assets/work/15.png'),
        电影发行单位设立审批: require('@/assets/work/16.png'),
        地方对等交流互办单一国家电影展活动审批: require('@/assets/work/17.png'),
        '《电影发行经营许可证》年检': require('@/assets/work/18.png'),
        '电影发行单位变更名称、地址、法定代表人（主要负责人）或终止电影发行经营活动备案': require('@/assets/work/19.png'),
      },
      recordList: [],
    }
  },
  mounted() {
    this.getMovieRecord()
  },
  methods: {
    handlerSearch() {
      this.getMovieRecord(this.searchValue)
    },
    getMovieRecord(value, type) {
      this.$axios({
        apiUrl: '/ycc/query/workHall',
        url: 'mgop.wasu.zyt.ycc0query0workHall',
        method: 'GET',
        data: {
          name: value,
          type: this.activeName,
        },
      }).then((res) => {
        this.recordList = res.result
      })
    },
    handleTabs(name) {
      this.getMovieRecord(this.searchValue, name)
    },
  },
}
</script>

<style lang="less" scoped>
.work {
  width: 100%;
  position: relative;
  .container {
    background: #f6f7f8;
    padding: 0 15px;
    min-height: calc(100vh - 56px);
    .wrapper {
      width: 100%;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 10px;
      li {
        width: 48%;
        height: 130px;
        border-radius: @border-radius-base;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        &:nth-child(odd) {
          margin-right: 11px;
          margin-bottom: 11px;
        }
        span {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }
}
//tab字体
::v-deep .van-tab--active {
  font-size: 16px;
}
</style>
